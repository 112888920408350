import React, { useRef } from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Dialog, Transition } from '@headlessui/react'

const ProjectModal = ({ project, onClose }) => {
  let mainTextRef = useRef(null)
  return (
    <Transition
      show={!!project}
      enter="transition duration-300 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        initialFocus={mainTextRef}
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setTimeout(() => onClose(), 300)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-sm sm:w-full">
            {project && (
              <>
                <div
                  ref={mainTextRef}
                  className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 prose"
                >
                  <Dialog.Title className="text-3xl mb-2">
                    {project.frontmatter.title}
                  </Dialog.Title>

                  <div className="flex items-center mb-2">
                    <div
                      className={`rounded-full w-4 h-4 ${
                        project.frontmatter.status === 'ongoing'
                          ? `bg-green-500`
                          : `bg-red-500`
                      }`}
                    ></div>
                    <span className="text-sm pl-2">
                      {project.frontmatter.status}
                    </span>
                  </div>
                  <Dialog.Description as={MDXRenderer}>
                    {project.body}
                  </Dialog.Description>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => {
                      setTimeout(() => onClose(), 300)
                    }}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ProjectModal
