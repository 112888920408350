import React, { useState } from 'react'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import { GatsbyImage } from "gatsby-plugin-image";
import ProjectModal from '../components/projectModal'

export default function Projects({ data, pageContext, ...props }) {
  
  const posts = data.allMdx.edges.map(({ node }) => node)
  const images = data.allFile.edges.map(({ node }) => node)

  const [project, setProject] = useState(null)

  return (
    <Layout>
      <Seo
        title={`Projects`}
        description={`List of projects done by Geoffrey Hunt. Call it a portfolio.`}
        type="page"
        path="/projects"
      />
        <ProjectModal project={project} onClose={()=>setProject(null)}/>
      <div className="bg-light pt-8 pb-5 pr-5 pl-5 md:pr-10 md:pl-10 mt-4 mb-4 max-w-full rounded-md prose">
        <h1>{`Projects`}</h1>
        <p>
          Here is a list of projects I worked on, finished and ongoing. I wish the list was longer and I was brave enough to put in all the fails.
        </p>
        <p>
            Also take a look at my Github account for some other experiments and open source work <a className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark inline-block hover:bg-light" target="_blank" rel="noopener noreferrer" href="https://github.com/runningdeveloper">github.com/runningdeveloper</a>
        </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4  mb-4">
        {posts.map(node => (
          <div key={node.id} className="bg-light rounded-md p-4">
            <div className="cursor-pointer  w-full" onClick={()=>setProject(node)}>
              <GatsbyImage
                image={images.find(
                  a => `${a.name}${a.ext}` === node.frontmatter.featured_image
                )?.childImageSharp?.gatsbyImageData}
                alt={`${node.frontmatter.title}`} />
            </div>
            <div className="prose">
                <div className="h-20 flex flex-col justify-center pb-2">
              <h2>{node.frontmatter.title}</h2>
              </div>
              <div className="flex items-center">
              <div className={`rounded-full w-4 h-4 ${node.frontmatter.status==='ongoing'?`bg-green-500`:`bg-red-500`}`}></div><span className="text-sm pl-2">{node.frontmatter.status}</span>
              </div>
              <p >{node.excerpt}</p>

              <button
              onClick={()=>setProject(node)}
              className="py-2 px-4 bg-bright text-white font-semibold rounded-lg shadow-md hover:bg-dark text-base">
                Details
              </button>

              {/* <Link

                className="text-lg underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light mr-2"
              >
                read more
              </Link> */}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`{
  allFile(filter: {dir: {glob: "**/projects/images/**"}, ext: {glob: "{.jpg,.jpeg,.png}"}}) {
    edges {
      node {
        relativePath
        name
        ext
        childImageSharp {
          gatsbyImageData(
            width: 280
            height: 200
            transformOptions: {cropFocus: CENTER}
            layout: FIXED
          )
        }
      }
    }
  }
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {fileAbsolutePath: {glob: "**/projects/**{.md,.mdx}"}}
  ) {
    edges {
      node {
        excerpt
        id
        body
        frontmatter {
          title
          description
          layout
          title
          categories
          status
          featured_image
          date(formatString: "MMMM DD, YYYY")
        }
        slug
      }
    }
  }
}
`
